<template>
  <div id="subpage-content">
    <CompanyDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.PartnTitle2") }}</h1>
        <div id="partn-cards">
          <v-col v-for="item in client" :key="item.id" :cols="item.id">
            <v-card width="250" height="250" id="serv-card" v-if="item.isclient">
              <img :src="getImageUrl(item)" href="" id="partn-pic" />
              <div id="partn-card-title">{{ getItemText(item) }}</div>
              <div id="partn-card-text">
                <div id="rowc">
                  <v-icon>mdi-link-variant</v-icon>

                  <a id="space">{{ getItemSite(item) }}</a>
                </div>
                <div>
                  <v-icon>mdi-phone</v-icon>

                  <a id="space">{{ getItemPhone(item) }} </a>
                </div>
              </div>
            </v-card>
          </v-col>
        </div>
      </v-card>
    </div>
  </div>
</template>

<style>
#serv-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#partn-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
}
#partn-card-title {
  display: flex;
  text-align: center;
  align-self: center;
}
#partn-card-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
#rowc {
  display: flex;
  align-items: center;
}
#space {
  margin: 5px;
}
#partn-pic {
  margin: 15px;
}
#dn {
  display: none;
}
</style>

<script>
import CompanyDrawer from "../../../views/Navigations/Company/CompanyDrawer";
export default {
  components: {
    CompanyDrawer,
  },
  data: () => ({
    client: [],
  }),
  async mounted() {
    await this.getPartnerblockClient();
  },
  methods: {
    async getPartnerblockClient() {
      await this.$store.dispatch("getAllPartnerblocks");
      this.client = await this.$store.getters.getPartnerblocks.filter((item) => item.isclient);
    },
    // .filter(item => item.isclient)
    getImageUrl(item) {
      return "data:image/jpeg;base64," + item.image;
    },
    getItemText(item) {
      if (this.$i18n.locale === "ru") {
        return item.text1;
      } else if (this.$i18n.locale === "gb") {
        return item.text2;
      }
    },
    getItemSite(item) {
      return item.site;
    },
    getItemPhone(item) {
      return item.phone;
    },
  },
};
</script>
